<template>
    <div class="wrapper" ref="container">
        <login
            v-if="!$route.meta.unlogged && !logged"
            @logged="
                updateLoggedUser();
                $router.push('/');
            "
        ></login>
        <div v-if="$route.meta.unlogged || logged">
            <menus
                :versao="versao"
                @logout="logged = false"
                :logoPath="logo"
                nome-sistema="TacTacs"
                :menu="menu"
            ></menus>
            <div class="content-wrapper">
                <div class="container-fluid">
                    <section class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-6">
                                    <h1>
                                        <i v-if="$route.meta.icon" :class="'nav-icon ' + $route.meta.icon"></i>
                                        {{ $route.meta.titulo }}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="content">
                        <div class="container-fluid">
                            <router-view></router-view>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <vuedal></vuedal>
        <Dialog
            header="Carregando dados, por favor aguarde..."
            :visible.sync="globalStore.loading"
            :closeOnEscape="false"
            :closable="false"
            :modal="true"
            class="loading"
        >
            <Box :loading="true" class="h-64"><br /><br /></Box>
        </Dialog>
        <footer class="main-footer text-sm">
            <div class="row">
                <div class="col-12 col-sm-5">
                    <strong>© 2024 | Parques TacTacs</strong> Todos os direitos reservados.
                </div>
                <div class="col-12 col-sm-6">
                    <!-- Celular <i class="fas fa-phone"></i> 85 99957-4415 |-->
                    E-mail: <i class="fas fa-envelope"></i> tactacs@tactacs.com.br
                </div>
                <div class="col-1 col-sm-1">
                    <div class="float-right d-none d-sm-inline">{{ versao }}</div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
import { AxiosResponse } from "axios";
import { BaseComponent } from "pedrociarlini-components/src/components/BaseComponent";
import Menu from "pedrociarlini-components/src/components/shared/menu/Menu.vue";
import Box from "pedrociarlini-components/src/components/shared/painel/Box.vue";
import SegurancaService from "pedrociarlini-components/src/domain/Seguranca/SegurancaService";
import AxiosHttp, { axiosConfig } from "pedrociarlini-components/src/domain/http/AxiosHttp";
import Dialog from "primevue/dialog/Dialog";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import { Component, Vue } from "vue-property-decorator";
import Login from "./components/pages/login/Login.vue";
import { menu, routes } from "./configs/router";
//@ts-ignore∏
import logo from "../src/assets/img/logo.png";

@Component({
    components: { menus: Menu, Login, Box, Dialog },
})
export default class App extends BaseComponent {
    static readonly components: {
        menus: typeof Menu;
        Box: typeof Box;
        Dialog: typeof Dialog;
    };
    logo = logo;

    axiosConfig = axiosConfig;
    routes = routes;
    menu = menu;
    versao = "X.Y.Z";
    logged = false;
    private loginPage = "#/";
    private segService = new SegurancaService(new AxiosHttp().http);
    uiData = { parque: { editando: false } };

    mounted() {
        this.updateLoggedUser();
    }

    created() {
        let axiosInstances: AxiosHttp[] = [];
        axiosInstances.push(new AxiosHttp());

        for (const axiosInstance of axiosInstances) {
            this.versao = process.env.APP_VERSION;
            axiosInstance.http.interceptors.response.use(
                (response: AxiosResponse) => {
                    return response;
                },
                (error) => {
                    let response = error.response;
                    if (response) {
                        if (response.status == 400) {
                            this.showErrorModal(response.data.message);
                            return Promise.reject(error);
                        } else if (response.status == 500) {
                            this.showErrorModal(
                                "Erro não esperado, favor contate a admistração do Sistema de Parques TacTacs (Blitz): " +
                                    response.data.message || JSON.stringify(response.data)
                            );
                            return Promise.reject(error);
                        } else if (response.status == 401) {
                            this.logged = false;
                            return response;
                        }
                    } else {
                        return Promise.reject(error);
                    }
                }
            );
        }
    }

    updateLoggedUser() {
        this.segService
            .loggedUser()
            .then((usuario) => {
                if (usuario && usuario.username && usuario.username.length > 1) {
                    usuario.perfis = [];
                    this.globalStore.setLoggedUser(usuario);
                    this.logged = true;
                    this.segService.listarPermissoesDeUsuario(usuario.username).then((perfis) => {
                        let logged = this.loggedUser;
                        logged.perfis = perfis;
                        this.globalStore.setLoggedUser(logged);
                    });
                } else {
                    Vue.$cookies.set("jwt", "");
                }
            })
            .catch((error) => {
                this.showErrorModal("Erro de acesso aos dados: " + error);
            });
    }
}
</script>

<style>
.pagina-enter,
.pagina-leave-active {
    opacity: 0;
}

.pagina-enter-active,
.pagina-leave-active {
    transition: opacity 0.5s;
}
.vuedals {
    z-index: 9873 !important;
}

.p-dialog-mask.p-component-overlay.loading {
    z-index: 9874 !important;
}
.main-footer {
    padding: 0.812rem;
}
</style>
