//@ts-ignore
import "admin-lte/plugins/bootstrap/js/bootstrap.bundle";
import "admin-lte/plugins/jquery-ui/jquery-ui";
import "admin-lte/plugins/jquery/jquery";
import "admin-lte/plugins/popper/popper";
import "autonumeric/dist/autoNumeric.min";
import jQuery from "jquery";

import "@fortawesome/fontawesome-free/css/all.css";
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/bootstrap4-light-purple/theme.css";

import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte.min";
import "admin-lte/plugins/icheck-bootstrap/";
import "select2/dist/css/select2.min.css";
import "toastr/build/toastr.css";
import "toastr/build/toastr.min";

import moment from "moment";
import "moment/locale/pt-br";
import Vue from "vue";
import VueCookie from "vue-cookies";
import VueRouter from "vue-router";
import { router } from "./configs/router";

import Modal from "pedrociarlini-components/src/components/shared/modal/Modal.vue";

//@ts-ignore
import { Component as Vuedal, default as Vuedals, Bus as VuedalsBus } from "vuedals";
//@ts-ignore
import PrimeVue from "primevue/config";
//@ts-ignore
import primeVueConfig from "pedrociarlini-components/src/configs/primeVueConfig";

import "./assets/css/style.css";
import { percFormater, realFormater } from "pedrociarlini-components/src/domain/Utils";

import { PiniaVuePlugin, createPinia } from "pinia";
import ConfirmationService from "primevue/confirmationservice";
import { axiosConfig } from "pedrociarlini-components/src/domain/http/AxiosHttp";

if (process.env.NODE_ENV == "production") {
    axiosConfig.baseURL = "https://artadm.pedrociarlini.com.br/artadm/api/";
    console.log(`Utilizando baseURL de ${process.env.NODE_ENV}: ` + axiosConfig.baseURL);
}

Object.defineProperty(Vue.prototype, "$jQuery", { value: jQuery });

Vue.use(ConfirmationService);

Vue.use(VueRouter);
Vue.use(VueCookie);
Vue.$cookies.config("7d");

Vue.use(Vuedals);
Vue.use(PrimeVue, primeVueConfig);

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("modal", Modal);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("vuedal", Vuedal);

Vue.filter("date", function (value: any) {
    if (value != 0 && !value) return "";
    else {
        return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    }
});

Vue.filter("dateTime", function (value: any) {
    if (value != 0 && !value) return "";
    else {
        return moment(value, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    }
});
Vue.filter("toDate", function (value: any) {
    if (typeof value !== "string") {
        return value;
    }
    return moment(value).format("L");
});

Vue.filter("toDateTime", function (value: any) {
    if (typeof value !== "string") {
        return value;
    }
    return moment(value).format("L LT");
});

Vue.filter("toTime", function (value: any) {
    if (typeof value !== "string") {
        return value;
    }
    return moment(value).format("LT");
});

Vue.filter("toCurrency", (value: any, defaultReplaceValue: string = "") => {
    if (value != 0 && !value) return defaultReplaceValue;
    else {
        return realFormater.format(value);
    }
});
Vue.filter("toPercent", (value: any) => {
    if (value != 0 && !value) return "";
    else {
        return percFormater.format(value);
    }
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
window.pinia = pinia;
// console.log("window.pinia criado");

import App from "./App.vue";

new Vue({
    el: "#app",
    router: router,
    render: (h) => h(App),
    pinia,
});
