import { UsuarioPessoaEntity } from "pedrociarlini-components/src/domain/Seguranca/UsuarioEntity";
import AxiosHttp from "pedrociarlini-components/src/domain/http/AxiosHttp";
import ConfirmacaoEmailEntity from "artadm-code/src/domain/Seguranca/ConfirmacaoEmailEntity";
import UsuarioEntity from "artadm-code/src/domain/Seguranca/UsuarioEntity";

export default class SegurancaParqueService extends AxiosHttp {
    async inserirUsuarioAutoCadastro(usuario: UsuarioPessoaEntity) {
        const res = await this.http.post(`/parque/meucadastro`, usuario);
        return res && res.status == 200 ? res.data : null;
    }

    async confirmarEmail(email: string, codigo: string): Promise<void> {
        const confirmacao = new ConfirmacaoEmailEntity(null, new UsuarioEntity(null, email), codigo);
        const res = await this.http.post(`/seguranca/confirmarEmail`, confirmacao);
        return res && res.status == 200 ? res.data : null;
    }
}
