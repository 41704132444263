export default class PerfilUsuarioEntity {
    id: number;
    nome: string;
    permissoes?: Array<{ id: number; nome: string }>;
    permissaoParqueNecessaria?: boolean = false;

    constructor(id: number, nome: string) {
        this.id = id;
        this.nome = nome;
    }
}

export enum PerfilUsuarioEnum {
    GESTAO = "Gestão",
    ATENDIMENTO = "Atendimento",
    ACERVO = "Acervo",
    FINANCEIRO = "Financeiro",
    PRODUTOR_INTERNO = "Produtor interno",
    PRODUTOR = "Produtor",
    PARQUES_GESTOR = "Gestor de Parques",
    PARQUES_SUPERVISOR = "Supervisor de Parques",
    PARQUE_CAIXA = "Caixa de Parque",
    PARQUE_OPERADOR = "Operador de Parque",
    PARQUE_CLIENTE = "Cliente parque",
}
