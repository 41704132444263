<template>
    <div class="login-page">
        <Transition name="sumir" :duration="500" mode="out-in">
            <div class="login-box" v-if="!ui.cadastrando" :key="`cadastro-${ui.cadastrando}`">
                <!-- /.login-logo -->

                <Card
                    :show-header="true"
                    header-type="card-outline card-success"
                    :show-footer="true"
                    :show-collapse="false"
                >
                    <template #header>
                        <a href="https://www.tactacs.com.br/" target="_blank">
                            <img class="w-100" src="../../../assets/img/logo_tactacs.webp" alt="Tac Tacs!!!" />
                        </a>
                    </template>
                    <template><h3>Em breve, um acesso facilitado para você! :)</h3></template></Card
                >

                <Card
                    :show-header="true"
                    header-type="card-outline card-success"
                    :show-footer="true"
                    :show-collapse="false"
                    v-show="false"
                >
                    <template #header>
                        <a href="https://www.tactacs.com.br/" target="_blank">
                            <img class="w-100" src="../../../assets/img/logo_tactacs.webp" alt="Tac Tacs!!!" />
                        </a>
                    </template>
                    <template>
                        <h2 class="text-center">Bem vindo aos parques Tac tacs</h2>
                        <p class="login-box-msg text-danger"></p>

                        <div class="input-group mb-3">
                            <input
                                class="form-control"
                                placeholder="email/cpf"
                                v-model="usuario.username"
                                @keyup.enter="logar"
                            />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-user"></span>
                                </div>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <input
                                type="password"
                                class="form-control"
                                placeholder="senha"
                                v-model="usuario.password"
                                @keyup.enter="logar"
                            />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <div class="text-center">
                            <button @click="logar" class="btn btn-success btn-block">Entrar</button>
                            <a @click="meCadastrar" href="javascript:void(0)">Quero me cadastrar</a>
                        </div>
                    </template>
                </Card>
            </div>
            <MeuCadastro
                v-else
                :key="`cadastro-${ui.cadastrando}`"
                class="card-cadastro"
                @cancelar="ui.cadastrando = false"
                @finalizado="ui.cadastrando = false"
            ></MeuCadastro>
        </Transition>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import SegurancaService from "pedrociarlini-components/src/domain/Seguranca/SegurancaService";
import UsuarioEntity from "pedrociarlini-components/src/domain/Seguranca/UsuarioEntity";
import Card from "pedrociarlini-components/src/components/shared/painel/Card.vue";
import MeuCadastro from "../usuario/MeuCadastro.vue";

@Component({ components: { Card, MeuCadastro } })
export default class LoginPage extends Vue {
    static readonly components: {
        Card: typeof Card;
        MeuCadastro: typeof MeuCadastro;
    };
    segService = new SegurancaService();
    usuario = new UsuarioEntity();

    ui = { cadastrando: false };

    created() {
        if (this.$route.query.cadastrando == "true") this.ui.cadastrando = true;
    }

    logar() {
        this.segService.logar(this.usuario).then(() => {
            this.$emit("logged", true);
        });
    }
    meCadastrar() {
        this.ui.cadastrando = true;
    }
}
</script>

<style>
.login-page {
    /* height: 100vh; */
    /* width: 100%; */
}
.sumir-enter,
.sumir-leave-to {
    opacity: 0;
    transform: translateX(-100vw);
}

.sumir-enter-to,
.sumir-leave {
    opacity: 1;
    transform: translateX(0px);
}

.sumir-enter-active,
.sumir-leave-active {
    transition: all 0.5s ease;
}

.card-cadastro {
    width: 90vw;
}
</style>
