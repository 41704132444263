import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { PerfilUsuarioEnum } from "artadm-code/src/domain/Seguranca/PerfilUsuarioEntity";
import { menuType } from "pedrociarlini-components/src/configs/router";

Vue.use(VueRouter);

export enum Routes {
    HOME = "/",
    CHECKIN_QRCODE = "/parque/unlogged/checkin",
}

// PATH == null indica que não deve ser clicável
const menu: menuType[] = [
    {
        path: Routes.HOME,
        name: "home",
        component: import("../components/pages/home/Home.vue"),
        meta: { titulo: "Home", menu: false, icon: "fas fa-list" },
        perfisPermitidos: null,
    },
    {
        path: "/parque/checkin",
        name: "parque_checkin",
        component: () => import("../components/pages/parque/CheckinPage.vue"),
        meta: { titulo: "Realizar pré-Checkin", menu: true, icon: "fas fa-sign-in-alt" },
        perfisPermitidos: [PerfilUsuarioEnum.PARQUE_CLIENTE],
    },
    {
        path: Routes.CHECKIN_QRCODE,
        name: "parque_unlogged_checkin",
        component: () => import("../components/pages/parque/CheckinQrCodePage.vue"),
        meta: { titulo: "Realizar pré-Checkin", menu: true, icon: "fas fa-sign-in-alt", unlogged: true },
        perfisPermitidos: [PerfilUsuarioEnum.PARQUE_CLIENTE],
    },
    {
        path: null as string,
        meta: { titulo: "Segurança", menu: true, icon: "fas fa-shield-alt" },
        perfisPermitidos: [PerfilUsuarioEnum.PARQUE_CLIENTE],
        itens: [
            {
                path: "/seguranca/alterarMinhaSenha",
                name: "seguranca_alterarMinhaSenha",
                component: () => import("../components/pages/usuario/AlterarMinhaSenha.vue"),
                meta: { titulo: "Alterar minha senha", menu: true, icon: "fas fa-key" },
                perfisPermitidos: [PerfilUsuarioEnum.PARQUE_CLIENTE],
            },
        ],
    },
];

const routes: RouteConfig[] = [];

function mountRoutes(menuItem?: menuType) {
    if (menuItem.path) {
        routes.push(menuItem);
    } else if (menuItem.itens) {
        menuItem.itens.forEach((m2) => {
            mountRoutes(m2);
        });
    }
}
menu.forEach((m) => {
    mountRoutes(m);
});

const router = new VueRouter({
    routes: routes,
});

export { menu, router, routes };
