<template>
    <div class="card-cadastro">
        <Card
            v-if="!ui.validandoEmail"
            nome="Cadastre-se"
            header-type="card-outline card-primary"
            :show-footer="true"
            :show-collapse="false"
        >
            <template #header>
                <a href="https://www.tactacs.com.br/" target="_blank" class="w-100 text-center d-block">
                    <img class="w-25" src="../../../assets/img/logo_tactacs.webp" alt="Tac Tacs!!!" />
                </a>
                <div class="row">
                    <div class="col-12 text-center">
                        <h3 class="text-center">
                            Bem vindo aos parques Tac tacs<br />Preencha os dados para se cadastrar.
                        </h3>
                    </div>
                </div>
            </template>

            <template>
                <div class="row">
                    <div class="col-6">
                        <CampoTexto
                            v-model="usuario.pessoa.nome"
                            label="Nome completo"
                            :obrigatorio="true"
                        ></CampoTexto>
                    </div>
                    <div class="col-6">
                        <CampoTexto
                            v-model="usuario.usuario.username"
                            label="E-mail (será usado para acessar o sistema de parques)"
                            :is-valid="emailOk"
                            :has-error="!emailOk"
                        ></CampoTexto>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <CampoTexto
                            v-model="usuario.usuario.password"
                            label="Senha"
                            :obrigatorio="true"
                            :password="true"
                            :is-valid="senhaOk"
                            :has-error="usuario.usuario.password && !senhaOk"
                        ></CampoTexto>
                        <ul class="text-sm text-info">
                            <li>
                                Mínimo de 8 caracteres.
                                <span class="badge badge-success" v-show="senhaPosusi8Caracteres"
                                    ><i class="fas fa-check"></i
                                ></span>
                            </li>
                            <li class="text-sm text-info">
                                Usar letras e números.
                                <span class="badge badge-success" v-show="senhaPosusiNumerosELetras"
                                    ><i class="fas fa-check"></i
                                ></span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <CampoTexto
                            v-model="ui.senha2"
                            label="Confirmação de senha"
                            :password="true"
                            :is-valid="confirmacaoSenhaOk"
                            :has-error="ui.senha2 != '' && !confirmacaoSenhaOk"
                        ></CampoTexto>
                    </div>
                </div>
            </template>
            <template #footer>
                <button class="btn btn-success mr-1" :disabled="!formOk" @click="cadastrar">Cadastrar</button>
                <button class="btn btn-danger" @click="$emit('cancelar', null)">Cancelar</button>
            </template>
        </Card>
        <Card
            v-else
            nome="Confirmando seu e-mail"
            header-type="card-outline card-primary"
            :show-footer="true"
            :show-collapse="false"
        >
            <template>
                <div class="row">
                    <div class="col-6">
                        <CampoTexto label="E-mail" v-model="usuario.usuario.username" :disabled="true"></CampoTexto>
                    </div>
                    <div class="col-6">
                        <CampoTexto
                            label="Código de verificação"
                            v-model="ui.codigo"
                            :obrigatorio="true"
                            input-class="codigo"
                        ></CampoTexto>
                    </div>
                </div>
            </template>
            <template #footer>
                <button class="btn btn-success" @click="confirmarEmail"><i class="fas fa-check"></i> Confirmar</button>
            </template>
        </Card>
    </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Card from "pedrociarlini-components/src/components/shared/painel/Card.vue";

import CampoTexto from "pedrociarlini-components/src/components/shared/campos/CampoTexto.vue";
import UsuarioEntity, { UsuarioPessoaEntity } from "pedrociarlini-components/src/domain/Seguranca/UsuarioEntity";
import PessoaEntity from "pedrociarlini-components/src/domain/usuario/PessoaEntity";
import Utils, { emailRegExp } from "pedrociarlini-components/src/domain/Utils";
import SegurancaParqueService from "../../../domain/usuario/SegurancaParqueService";

@Component({ components: { Card, CampoTexto } })
export default class MeuCadastro extends Vue {
    static readonly components: {
        Card: typeof Card;
        CampoTexto: typeof CampoTexto;
    };
    private static letraNumeroRegExp = /[a-zA-Z]/;
    segService = new SegurancaParqueService();

    usuario: UsuarioPessoaEntity = new UsuarioPessoaEntity(null, new UsuarioEntity(), new PessoaEntity());

    ui = {
        senha2: "",
        validandoEmail: false,
        codigo: "",
    };

    get confirmacaoSenhaOk(): boolean {
        return this.usuario.usuario.password === this.ui.senha2;
    }

    get senhaOk(): boolean {
        return this.senhaPosusi8Caracteres && this.senhaPosusiNumerosELetras;
    }

    get senhaPosusiNumerosELetras(): boolean {
        return MeuCadastro.letraNumeroRegExp.test(this.usuario.usuario.password);
    }
    get senhaPosusi8Caracteres(): boolean {
        return this.usuario.usuario.password && this.usuario.usuario.password.length >= 8;
    }

    get emailOk(): boolean {
        const username = this.usuario.usuario.username;
        return username && username.length >= 3 && emailRegExp.test(username);
    }

    get formOk(): boolean {
        return (
            this.senhaOk &&
            this.confirmacaoSenhaOk &&
            !!this.usuario.pessoa.nome &&
            !!this.usuario.usuario.username &&
            this.emailOk
        );
    }

    cadastrar() {
        this.segService.inserirUsuarioAutoCadastro(this.usuario).then(() => {
            Utils.exibirMsg("Usuário cadastrado. Valide seu email.");
            this.ui.validandoEmail = true;
        });
    }

    confirmarEmail() {
        this.segService.confirmarEmail(this.usuario.usuario.username, this.ui.codigo).then(() => {
            Utils.exibirMsg("Email validado com sucesso.\nJá pode entrar no sistema!");
            this.$emit("finalizado", null);
        });
    }

    created() {
        if (this.$route.query.validandoEmail == "true") {
            this.ui.validandoEmail = true;
            if (!this.usuario.usuario.username && !!this.$route.query.email)
                this.usuario.usuario.username = this.$route.query.email as string;
        }
    }
}
</script>
<style>
.codigo {
    font-family: "Courier New", Courier, monospace;
    font-size: 18pt;
}
</style>
